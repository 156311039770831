import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';  
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  margin-top: 200px;
  
  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 800px;
        margin: 1.75rem auto;
    }
  }
`

const StyledModalBody = styled(Modal.Body)`
  padding: 0;
  border: 4px solid black !important;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
`

const ExitModal = (props) => {
  const [show, setShow] = useState(props.show)

  useEffect(() => {
    if (props.show) {
      let expiryDate = new Date(
        Date.now() + 14 * (1000 * 60 * 60 * 24)
      )
      expiryDate.setFullYear(expiryDate.getFullYear() + 1)
      document.cookie =
        "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
    }
    
    setShow(props.show)

  }, [props.show])
  
  return (
    <StyledModal show={show} onHide={() => {props.setShowPopup(false)}}>
      <StyledModalBody>
        {props.children}
      </StyledModalBody>
    </StyledModal>     
  )
}

export default ExitModal
