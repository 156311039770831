import React from "react"
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import focusBackground from '../assets/images/focus-background.png';
import Button from './buttons/button';

const FocusPopUpContainer = styled(Container)`
  background-image: url(${focusBackground});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;

  h3 {
    font-family: 'roboto-mono';
  }

  .focus-list {
    margin-top: 20px;
    margin-left: 35px;

    li {
      margin-bottom: 5px;
    }
  }

  .focus-button-container {

  }
`

const ModalInner = styled.div`
  /* display: flex; */
  width: 100%;
  /* flex-direction: inherit; */
  align-items: center;
  background: transparent;
  border-style: solid;
  border-width: 0px;
  border-color: #000000;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  padding-top: 9%;
  padding-right: 5%;
  padding-bottom: 11%;
  padding-left: 40%;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  box-shadow: 0px 0px 0px 0px #ffffff;
  max-width: 100%;
`

const FocusLeadGen = () => {
  return (
    <FocusPopUpContainer>
      <ModalInner>
        <h3>Get FREE Access to the 4-Hour Course that Will Teach You <strong>"How to Focus When Learning to Code"</strong></h3>
        <ul className='focus-list'>
          <li>Build a Weekly Coding Schedule</li>
          <li>Making Coding a Habit</li>
          <li>Kill Distraction</li>
          <li>Achieve Your Goals</li>
        </ul>
        <div className='focus-button-container'>
          <Link to='/focus'>
            <Button text={'Get the Course - FREE'} />
          </Link>
        </div>
      </ModalInner>
      
    </FocusPopUpContainer>
  )
}

export default FocusLeadGen;