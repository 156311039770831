import { useState, useEffect } from 'react';
import ExitIntent from "../utils/ExitIntent"

export default function () {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (document.cookie.indexOf("modal_seen=true") < 0) {
      const removeExitIntent = ExitIntent({
        threshold: 10,
        eventThrottle: 25,
        onExitIntent: () => {
          setShowPopup(true)
        },
      })
      return () => {
        removeExitIntent()
      }
    }
  });

  return {
    showPopup,
    setShowPopup
  }
}