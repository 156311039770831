import styled from "styled-components"
import React from "react"

import Check from "../assets/images/check.png"

const ComparisonTable = styled.div`
  overflow: hidden;
  width: 90%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  border: 4px solid var(--black);
  box-shadow: -15px 15px var(--black);

  .table-top {
    display: flex;
    width: 100%;
    height: 45px;
    font-family: "roboto-mono";
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    background-color: var(--white);

    @media screen and (max-width: 479px) {
      height: 60px;
      padding-right: 5px;
    }
  }

  .table-top-column {
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    font-family: "roboto-mono";

    .column-title {
      color: var(--black);
      font-size: 20px;

      @media screen and (max-width: 767px) {
        font-size: 22px;
      }

      @media screen and (max-width: 479px) {
        font-size: 16px;
      }
    }
  }

  .table-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 45px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: var(--white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    @media screen and (max-width: 767px) {
      height: 55px;
      padding-left: 5px;
    }
  }

  .table-column {
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    font-family: "roboto-mono";

    div {
      @media screen and (max-width: 479px) {
        text-align: left;
        font-size: 11px;
      }
    }
  }

  .right {
    justify-content: flex-end;

    @media screen and (max-width: 479px) {
      text-align: left;
    }
  }

  .check {
    width: 30px;
    margin-bottom: 0;
  }

  .category {
    justify-content: flex-end;
    text-align: right;
    padding-left: 0;
    margin-left: 0;

    @media screen and (max-width: 479px) {
      text-align: left;
      font-size: 11px;
    }
  }
`

const Chart = () => {
  return (
    <ComparisonTable>
      <div className="table-top">
        <div className="table-top-column"></div>
        <div className="table-top-column">
          <div className="column-title">CS Degree</div>
        </div>
        <div className="table-top-column">
          <div className="column-title">Parsity</div>
        </div>
        <div className="table-top-column">
          <div className="column-title">Bootcamp</div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>Total Tuition</div>
        </div>
        <div className="table-column">
          <div>$80k-150k</div>
        </div>
        <div className="table-column">
          <div>$9,200</div>
        </div>
        <div className="table-column">
          <div>$16k-26k</div>
        </div>
      </div>

      <div className="table-line">
        <div className="table-column right">
          <div>Time Commitment</div>
        </div>
        <div className="table-column">
          <div>4 years</div>
        </div>
        <div className="table-column">
          <div>3-6 months</div>
        </div>
        <div className="table-column">
          <div>3 months</div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>1 on 1 Mentors</div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>Transparent Statistics</div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>1 on 1 Career Coaching</div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>Career Curriculum</div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>Real World Technologies</div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
      </div>
      <div className="table-line">
        <div className="table-column right">
          <div>Computer Science Topics</div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div>
            <img alt="" className="check" src={Check}></img>
          </div>
        </div>
        <div className="table-column">
          <div></div>
        </div>
      </div>
    </ComparisonTable>
  )
}

export default Chart
